import React, { useEffect, useState, useContext } from "react";
import { ReactReduxContext } from "react-redux";
import { api_url } from "../../data";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../components/Breadcrumbs";
export const CollectionsGrid = ({ colTypePage }) => {
  const [ColVol, setColVol] = useState(colTypePage);
  const [ColType, setColType] = useState(1);
  const [VolunteerSel, setVolunteerSel] = useState("All");

  const { store } = useContext(ReactReduxContext);
  const state = store.getState();

  let navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (ColVol === "Collections") {
      setColVol("Collections");
      navigate("/collections");
    } else if (ColVol === "Volunteers") {
      setColVol("Volunteers");
      navigate("/volunteers");
    }
  }, [ColVol]);

  useEffect(() => {
    if (location.pathname === "/collections") {
      setColVol("Collections");
    }
    if (location.pathname === "/volunteers") {
      setColVol("Volunteers");
    }
  }, [location.pathname]);

  function getDate(date) {
    if (date?.length > 0) {
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    } else {
      return "";
    }
  }
  function getYear(date) {
    if (date?.length > 0) {
      const [year] = date.split("-");
      return `${year}`;
    } else {
      return "2024";
    }
  }
  function getActiveCollection(value) {
    let now = new Date();
    let date = new Date(value);
    return date > now ? "" : "old";
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    let searchResults =
      searchTerm.length > 0
        ? state.data.data.volunteers.filter((volunteer) =>
            volunteer.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : [];
    const searchResultsCollections =
      searchTerm.length > 0
        ? state.data.data.collections.filter((collection) =>
            collection.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : [];
    searchResults = [...searchResults, ...searchResultsCollections];
    console.log(searchResults);
    setSearchResults(searchResults);
    setSearchTerm(searchTerm);
  };

  return (
    <>
      <div className="collections__list retreat">
        {state.data.data.categories.length &&
          state.data.data.categories.map((type, index) => (
            <label key={index} className="btn btn--transparent">
              <input
                type="radio"
                name="collectionsType"
                value={type.id}
                checked={ColType === type.id}
                onClick={() => setColType(type.id)}
                id={`collectionsType_${index}`}
                readOnly
                style={{ display: "none" }}
              />
              <p className="fs-20 fw5">{type.name}</p>
            </label>
          ))}
      </div>
      <section id="collections" className="fs-48 retreat fs-16 collections">
        <div id="volunteers" className="collections__title">
          <h2 className="fs-32 fw7 lh-120">Выберите, кому хотите помочь</h2>
          <div className="collections__title__buttons">
            <label>
              <input
                type="radio"
                name="ColVol"
                value="Collections"
                checked={ColVol === "Collections"}
                onClick={() => setColVol("Collections")}
                id=""
                readOnly
                style={{ display: "none" }}
              />
              <p className="fs-20 fw5">Сборы</p>
            </label>
            <label>
              <input
                type="radio"
                name="ColVol"
                value="Volunteers"
                checked={ColVol === "Volunteers"}
                onClick={() => setColVol("Volunteers")}
                id=""
                readOnly
                style={{ display: "none" }}
              />
              <p className="fs-20 fw5">Волонтеры</p>
            </label>
          </div>
        </div>
        <div className="searchForm">
          <form action="">
            <input
              type="text"
              name=""
              id=""
              placeholder="Искать волонтера или сбор"
              className="fs-20 fw4"
              value={searchTerm}
              onChange={handleSearch}
            />
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.51562 2.84375C5.83085 2.84375 2.84375 5.83085 2.84375 9.51562C2.84375 13.2004 5.83085 16.1875 9.51562 16.1875C11.3565 16.1875 13.0219 15.4431 14.2299 14.2368C15.4403 13.0282 16.1875 11.36 16.1875 9.51562C16.1875 5.83085 13.2004 2.84375 9.51562 2.84375ZM1.09375 9.51562C1.09375 4.86435 4.86435 1.09375 9.51562 1.09375C14.1669 1.09375 17.9375 4.86435 17.9375 9.51562C17.9375 11.5274 17.2312 13.3755 16.0545 14.8237L19.6494 18.412C19.9914 18.7534 19.9919 19.3074 19.6505 19.6494C19.3091 19.9914 18.7551 19.9919 18.4131 19.6505L14.8164 16.0604C13.3693 17.2336 11.524 17.9375 9.51562 17.9375C4.86435 17.9375 1.09375 14.1669 1.09375 9.51562Z"
                  fill="#141414"
                />
              </svg>
            </button>
          </form>
          {searchResults.length > 0 && (
            <ul className="search__home__content__list scrollBar">
              {searchResults.map((result) => (
                <li key={result.id}>
                  <a
                    href={
                      result.user_id
                        ? `/collections/${result.id}`
                        : `/volunteers/${result.id}`
                    }
                  >
                    <img
                      src={
                        result.user_id
                          ? `${api_url}${result.image}`
                          : `${api_url}${result.avatar}`
                      }
                      alt={result.name}
                      className={result.user_id ? "collection" : "volunteer"}
                    />
                    <p className="fs-18 fw4">{result.name}</p>
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
        {VolunteerSel !== "All" && (
          <button
            className="volunteer__select"
            onClick={() => setVolunteerSel("All")}
          >
            <div className="volunteer__select__text">
              <p className="fs-20 fw5 lh-120">Сборы: </p>
              <span className="fs-20 fw5 lh-120">{VolunteerSel.name}</span>
            </div>
            <div className="button-close"></div>
          </button>
        )}
        <div className="collections__grid">
          {ColType === "Все программы" &&
            ColVol === "Collections" &&
            state.data.data.collections.map((value, index) => (
              <div
                className={`collections__grid__block ${getActiveCollection(
                  value.end_date
                )}`}
                key={index}
              >
                <div
                  className="collections__grid__block__img"
                  style={{ backgroundImage: `url(${api_url + value.image})` }}
                ></div>
                <div className="collections__grid__block__volunteer">
                  <img src={api_url + value.user.avatar} alt="" />
                  <p className="fs-14 fw3 lh-120">{value.user.name}</p>
                </div>
                <h5 className="fs-18 fw5">{value.name}</h5>
                <p className="fs-14 fw3">{value.description}</p>
                <div className="collections__grid__block__progress">
                  <progress value={value.progress} max="100" />
                  <div className="collections__grid__block__progress__dates">
                    <p className="fs-14 fw3">{getDate(value.start_date)}</p>
                    <p className="fs-14 fw3">{getDate(value.end_date)}</p>
                  </div>
                </div>
                <div className="collections__grid__block__links">
                  <Link
                    className="btn btn--blue"
                    to={`/collections/${value.id}`}
                  >
                    Перейти на сбор
                  </Link>
                  <Link
                    className="btn btn--circle btn--blue"
                    to={`/volunteers/${value.user.id}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="24"
                      viewBox="0 0 16 24"
                      fill="none"
                    >
                      <path
                        d="M7.45224 14.0182C3.19051 14.4034 -0.0563382 18.0045 0.000239928 22.2832V22.5002C0.000239928 23.3286 0.671818 24.0002 1.50024 24.0002C2.32866 24.0002 3.00024 23.3286 3.00024 22.5002V22.2232C2.95515 19.5961 4.8939 17.3558 7.50024 17.0232C10.2515 16.7504 12.703 18.7596 12.9758 21.5108C12.9919 21.6735 13.0001 21.8368 13.0002 22.0002V22.5002C13.0002 23.3286 13.6718 24.0002 14.5002 24.0002C15.3286 24.0002 16.0002 23.3286 16.0002 22.5002V22.0002C15.9953 17.5769 12.4056 13.9951 7.9824 14C7.80554 14.0002 7.62872 14.0063 7.45224 14.0182Z"
                        fill="white"
                      />
                      <path
                        d="M8.00049 12C11.3142 12 14.0005 9.31369 14.0005 6C14.0005 2.68631 11.3142 0 8.00049 0C4.6868 0 2.00049 2.68631 2.00049 6C2.00377 9.31233 4.68816 11.9967 8.00049 12ZM8.00049 3C9.65733 3 11.0005 4.34316 11.0005 6C11.0005 7.65684 9.65733 9 8.00049 9C6.34364 9 5.00049 7.65684 5.00049 6C5.00049 4.34316 6.34364 3 8.00049 3Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            ))}
          {ColType !== "Все программы" &&
            ColVol === "Collections" &&
            state.data.data.collections
              .filter((val) => val.category_id === parseInt(ColType))
              .map((value, index) => (
                <div
                  className={`collections__grid__block ${getActiveCollection(
                    value.end_date
                  )}`}
                  key={index}
                >
                  <div
                    className="collections__grid__block__img"
                    style={{ backgroundImage: `url(${api_url + value.image})` }}
                  ></div>
                  <div className="collections__grid__block__volunteer">
                    <img src={api_url + value.user.avatar} alt="" />
                    <p className="fs-14 fw3 lh-120">{value.user.name}</p>
                  </div>
                  <h5 className="fs-18 fw5">{value.name}</h5>
                  <p className="fs-14 fw3">{value.description}</p>
                  <div className="collections__grid__block__progress">
                    <progress value={value.progress} max="100" />
                    <div className="collections__grid__block__progress__dates">
                      <p className="fs-14 fw3">{getDate(value.start_date)}</p>
                      <p className="fs-14 fw3">{getDate(value.end_date)}</p>
                    </div>
                  </div>
                  <div className="collections__grid__block__links">
                    <Link
                      className="btn btn--blue"
                      to={`/collections/${value.id}`}
                    >
                      Перейти на сбор
                    </Link>
                    <Link
                      className="btn btn--circle btn--blue"
                      to={`/volunteers/${value.user.id}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="24"
                        viewBox="0 0 16 24"
                        fill="none"
                      >
                        <path
                          d="M7.45224 14.0182C3.19051 14.4034 -0.0563382 18.0045 0.000239928 22.2832V22.5002C0.000239928 23.3286 0.671818 24.0002 1.50024 24.0002C2.32866 24.0002 3.00024 23.3286 3.00024 22.5002V22.2232C2.95515 19.5961 4.8939 17.3558 7.50024 17.0232C10.2515 16.7504 12.703 18.7596 12.9758 21.5108C12.9919 21.6735 13.0001 21.8368 13.0002 22.0002V22.5002C13.0002 23.3286 13.6718 24.0002 14.5002 24.0002C15.3286 24.0002 16.0002 23.3286 16.0002 22.5002V22.0002C15.9953 17.5769 12.4056 13.9951 7.9824 14C7.80554 14.0002 7.62872 14.0063 7.45224 14.0182Z"
                          fill="white"
                        />
                        <path
                          d="M8.00049 12C11.3142 12 14.0005 9.31369 14.0005 6C14.0005 2.68631 11.3142 0 8.00049 0C4.6868 0 2.00049 2.68631 2.00049 6C2.00377 9.31233 4.68816 11.9967 8.00049 12ZM8.00049 3C9.65733 3 11.0005 4.34316 11.0005 6C11.0005 7.65684 9.65733 9 8.00049 9C6.34364 9 5.00049 7.65684 5.00049 6C5.00049 4.34316 6.34364 3 8.00049 3Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              ))}
          {ColVol === "Volunteers" &&
            VolunteerSel === "All" &&
            state.data.data.volunteers.map((value, index) => (
              <div className="collections__grid__volunteer" key={index}>
                <div className="collections__grid__volunteer__info">
                  <img src={api_url + value.avatar} alt="" />
                  <div className="collections__grid__volunteer__info__name">
                    <p className="fs-20 fw5 lh-120">{value.name}</p>
                    <p className="fs-14 fw3 lh-120">Аккредитованный волонтер</p>
                  </div>
                </div>
                <div className="collections__grid__volunteer__since">
                  <span className="fs-14 fw3 lh-120">На платформе</span>
                  <span className="fs-14 fw7 lh-120">
                    c {getYear(value.created_at)}
                  </span>
                </div>
                <div className="collections__grid__volunteer__direction">
                  <p className="fs-14 fw7">Направление волонтера</p>
                  <div className="collections__grid__volunteer__direction__items">
                    {value.categories?.length > 0 &&
                      value.categories.map((value, index) => (
                        <p
                          className="fs-14 fw3 lh-120 btn btn--square btn--transparent"
                          key={index}
                        >
                          {value.name}
                        </p>
                      ))}
                    {value.categories?.length === 0 && (
                      <p className="fs-14 fw3 lh-120 btn btn--square btn--transparent">
                        Направления не выбраны
                      </p>
                    )}
                  </div>
                </div>
                <div className="collections__grid__volunteer__direction">
                  <p className="fs-14 fw7">Регионы волонтера</p>
                  <div className="collections__grid__volunteer__direction__items">
                    {value.locations?.length > 0 &&
                      value.locations.slice(0, 2).map((value, index) => (
                        <p
                          className="fs-14 fw3 lh-120 btn btn--square btn--transparent"
                          key={index}
                        >
                          {value.name}
                        </p>
                      ))}
                    {value.locations?.length > 2 && (
                      <p className="fs-14 fw3 lh-120 btn btn--square btn--transparent">
                        +{value.locations?.length - 2}
                      </p>
                    )}
                    {value.locations?.length === 0 && (
                      <p className="fs-14 fw3 lh-120 btn btn--square btn--transparent">
                        Регионы не выбраны
                      </p>
                    )}
                  </div>
                </div>
                <div className="collections__grid__volunteer__direction">
                  <p className="fs-14 fw7">Информация по сборам</p>
                  <div className="collections__grid__volunteer__direction__counter">
                    <p className="fs-14 fw7">{value.collections.length}</p>
                    <span></span>
                    <div className="label label--active">
                      <span></span>
                      <p className="fs-14 fw7">Активные</p>
                    </div>
                  </div>
                </div>
                <div className="collections__grid__block__links">
                  <button
                    onClick={() => setVolunteerSel(value)}
                    className="btn btn--blue"
                  >
                    Посмотреть сборы
                  </button>
                  <Link
                    className="btn btn--circle btn--blue"
                    to={`/volunteers/${value.id}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="24"
                      viewBox="0 0 16 24"
                      fill="none"
                    >
                      <path
                        d="M7.45224 14.0182C3.19051 14.4034 -0.0563382 18.0045 0.000239928 22.2832V22.5002C0.000239928 23.3286 0.671818 24.0002 1.50024 24.0002C2.32866 24.0002 3.00024 23.3286 3.00024 22.5002V22.2232C2.95515 19.5961 4.8939 17.3558 7.50024 17.0232C10.2515 16.7504 12.703 18.7596 12.9758 21.5108C12.9919 21.6735 13.0001 21.8368 13.0002 22.0002V22.5002C13.0002 23.3286 13.6718 24.0002 14.5002 24.0002C15.3286 24.0002 16.0002 23.3286 16.0002 22.5002V22.0002C15.9953 17.5769 12.4056 13.9951 7.9824 14C7.80554 14.0002 7.62872 14.0063 7.45224 14.0182Z"
                        fill="white"
                      />
                      <path
                        d="M8.00049 12C11.3142 12 14.0005 9.31369 14.0005 6C14.0005 2.68631 11.3142 0 8.00049 0C4.6868 0 2.00049 2.68631 2.00049 6C2.00377 9.31233 4.68816 11.9967 8.00049 12ZM8.00049 3C9.65733 3 11.0005 4.34316 11.0005 6C11.0005 7.65684 9.65733 9 8.00049 9C6.34364 9 5.00049 7.65684 5.00049 6C5.00049 4.34316 6.34364 3 8.00049 3Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            ))}
          {ColVol === "Volunteers" &&
            VolunteerSel !== "All" &&
            VolunteerSel.collections.map((value, index) => (
              <div
                className={`collections__grid__block ${getActiveCollection(
                  value.end_date
                )}`}
                key={index}
              >
                <div
                  className="collections__grid__block__img"
                  style={{ backgroundImage: `url(${api_url + value.image})` }}
                ></div>
                <div className="collections__grid__block__volunteer">
                  <img src={api_url + VolunteerSel.avatar} alt="" />
                  <p className="fs-14 fw3 lh-120">{VolunteerSel.name}</p>
                </div>
                <h5 className="fs-18 fw5">{value.name}</h5>
                <p className="fs-14 fw3">{value.description}</p>
                <div className="collections__grid__block__progress">
                  <progress value={value.progress} max="100" />
                  <div className="collections__grid__block__progress__dates">
                    <p className="fs-14 fw3">{getDate(value.start_date)}</p>
                    <p className="fs-14 fw3">{getDate(value.end_date)}</p>
                  </div>
                </div>
                <div className="collections__grid__block__links">
                  <Link
                    className="btn btn--blue"
                    to={`/collections/${value.id}`}
                  >
                    Перейти на сбор
                  </Link>
                  <Link
                    className="btn btn--circle btn--blue"
                    to={`/volunteers/${VolunteerSel.id}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="24"
                      viewBox="0 0 16 24"
                      fill="none"
                    >
                      <path
                        d="M7.45224 14.0182C3.19051 14.4034 -0.0563382 18.0045 0.000239928 22.2832V22.5002C0.000239928 23.3286 0.671818 24.0002 1.50024 24.0002C2.32866 24.0002 3.00024 23.3286 3.00024 22.5002V22.2232C2.95515 19.5961 4.8939 17.3558 7.50024 17.0232C10.2515 16.7504 12.703 18.7596 12.9758 21.5108C12.9919 21.6735 13.0001 21.8368 13.0002 22.0002V22.5002C13.0002 23.3286 13.6718 24.0002 14.5002 24.0002C15.3286 24.0002 16.0002 23.3286 16.0002 22.5002V22.0002C15.9953 17.5769 12.4056 13.9951 7.9824 14C7.80554 14.0002 7.62872 14.0063 7.45224 14.0182Z"
                        fill="white"
                      />
                      <path
                        d="M8.00049 12C11.3142 12 14.0005 9.31369 14.0005 6C14.0005 2.68631 11.3142 0 8.00049 0C4.6868 0 2.00049 2.68631 2.00049 6C2.00377 9.31233 4.68816 11.9967 8.00049 12ZM8.00049 3C9.65733 3 11.0005 4.34316 11.0005 6C11.0005 7.65684 9.65733 9 8.00049 9C6.34364 9 5.00049 7.65684 5.00049 6C5.00049 4.34316 6.34364 3 8.00049 3Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
};
