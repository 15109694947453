import React from "react";
import { SearchHome } from "./templates/SearchHome";
import { BenifitsGrid } from "./templates/Benifits";
import { RouteHome } from "./components/routeHome";
import { NewsHome } from "./components/newsHome";

export const Homepage = () => {
  return (
    <>
      <SearchHome />
      <RouteHome />
      <BenifitsGrid />
      <NewsHome />
    </>
  );
};
