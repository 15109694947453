import React from "react";
// import benifitImage1 from "../../assets/images/Rectangle1.svg";
// import benifitImage2 from "../../assets/images/Rectangle2.svg";
// import benifitImage3 from "../../assets/images/Rectangle3.svg";
// import benifitImage4 from "../../assets/images/Rectangle4.svg";
import benifitImage from "../../assets/images/benifitsImage.svg";
export const BenifitsGrid = () => {
  return (
    <section className="wrapper retreat fs-32">
      <h2 className="fs-48 fw7 lh-120">Прозрачность во всём</h2>
      <div className="benifits retreat fs-16">
        <div className="benifits__block">
          <img src={benifitImage} alt="Преимущество" />
          <h3 className="fs-20 fw6">Прозрачность и открытость</h3>
          <p className="fs-16 fw3 lh-120">
            Волонтеры тщательно следят, чтобы все взносы на их сборы были
            видимыми и эффективными
          </p>
        </div>
        <div className="benifits__block">
          <img src={benifitImage} alt="Преимущество" />
          <h3 className="fs-20 fw6">Коммуникация и обратная связь</h3>
          <p className="fs-16 fw3 lh-120">
            Волонтеры остаются на прямой связи и предоставляют отчеты о
            проделанной работе
          </p>
        </div>
        <div className="benifits__block">
          <img src={benifitImage} alt="Преимущество" />
          <h3 className="fs-20 fw6">Информационная площадка</h3>
          <p className="fs-16 fw3 lh-120">
            Мы не занимаемся сбором денежных средств, но предоставляем площадку
            для поиска волонтеров
          </p>
        </div>
        <div className="benifits__block">
          <img src={benifitImage} alt="Преимущество" />
          <h3 className="fs-20 fw6">Социальная ответственность</h3>
          <p className="fs-16 fw3 lh-120">
            Даем каждому возможность помочь военным в их непростом положении
          </p>
        </div>
      </div>
    </section>
  );
};
